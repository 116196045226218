import { Body } from '@lendi-ui/typography';
import AddressPicker from '@lendi/address-picker';
import * as React from 'react';
import { LalaSentry } from '@lendi/lala-utils';
import { Alert, InfoWrapper } from './style';
export interface AddressObject {
  buildingName?: string;
  country?: string;
  deliveryAddress?: string;
  level?: string;
  lotSection?: string;
  postcode?: string;
  state?: string;
  streetName?: string;
  streetNumber?: string;
  streetSuffix?: string;
  streetType?: string;
  suburb?: string;
  unit?: string;
}

export interface PostcodePickerProps {
  onSelect: (selectedValue: AddressObject) => void;
  onChange: () => void;
  onReset: () => void;
  canContinue: boolean;
}

export const PostcodePicker = ({ onSelect, onReset, onChange, canContinue }: PostcodePickerProps) => {
  return (
    <>
      <AddressPicker
        data-testid="postcode-input"
        size="md"
        onSelectAddress={onSelect}
        placeholder="Enter postcode or suburb…"
        onChange={onChange}
        onReset={onReset}
        source={'aussie'}
        regionSearchOnly={true}
        onException={(e: Error) => LalaSentry.logToSentry('Address picker in Lala: ${e}')}
      />
      {!canContinue && (
        <Alert>
          <Body color="warn.500" size="xs">
            <InfoWrapper color="warn.500" /> Please enter a postcode or suburb
          </Body>
        </Alert>
      )}
    </>
  );
};
