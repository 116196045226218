import { Grid, unit } from '@lendi-ui/grid';
import { mt, my } from '@lendi-ui/spacing';
import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  text-align: center;
  ${mt('lg')}
`;

export const SecondSection = styled.div`
  ${my('md')};
`;

export const ThirdSection = styled.div``;

export const SubGrid = styled.div`
  ${unit({
    size: { xs: 1, sm: 1, lg: 4 },
  })}
`;

// @ts-ignore
export const PageWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
