import {
  PRL_BROKER_ID,
  PRL_STORE_ID,
  CUSTOMER_REFERRAL_CODE,
  PRL_COMPANY_GEN,
  PRL_CAMPAIGN_NAME,
} from '@lendi/lala-utils';
import { ParamName, getParamFromReturnUrl } from './getParamFromReturnUrl';

export function setPrlAndReferralParamsToStorage() {
  const params = new URLSearchParams(window.location.search?.toLowerCase());

  const storeIdFromLocalStorage = localStorage.getItem(PRL_STORE_ID);
  const brokerIdFromLocalStorage = localStorage.getItem(PRL_BROKER_ID);
  const refCodeFromLocalStorage = localStorage.getItem(CUSTOMER_REFERRAL_CODE);
  const companyGenFromLocalStorage = localStorage.getItem(PRL_COMPANY_GEN);
  const campaignNameFromLocalStorage = localStorage.getItem(PRL_CAMPAIGN_NAME);

  const isParamSet =
    storeIdFromLocalStorage ||
    brokerIdFromLocalStorage ||
    refCodeFromLocalStorage ||
    companyGenFromLocalStorage ||
    campaignNameFromLocalStorage;

  if (!isParamSet) {
    const storeId = params.get('storeid') || getParamFromReturnUrl(params, ParamName.StoreId);
    if (storeId) {
      localStorage.setItem(PRL_STORE_ID, storeId);
    }
    const brokerId = params.get('brokerid') || getParamFromReturnUrl(params, ParamName.BrokerId);
    if (brokerId) {
      localStorage.setItem(PRL_BROKER_ID, brokerId);
    }
    const refCode = params.get('refcode');
    if (refCode) {
      localStorage.setItem(CUSTOMER_REFERRAL_CODE, refCode);
    }
    const companyGen = params.get('cgen');
    if (companyGen) {
      localStorage.setItem(PRL_COMPANY_GEN, companyGen);
    }
    const campaignName = params.get('cname');
    if (campaignName) {
      localStorage.setItem(PRL_CAMPAIGN_NAME, campaignName);
    }
  }
}
