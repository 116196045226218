export enum ParamName {
  StoreId = 'storeid',
  BrokerId = 'brokerid',
}

export const getParamFromReturnUrl = (params: URLSearchParams, paramName: ParamName): string | null => {
  const returnUrlParam = params.get('returnurl');

  if (!returnUrlParam) return null;

  const index = returnUrlParam.indexOf('?');

  if (index === -1) return null;

  const returnUrlParams = new URLSearchParams(returnUrlParam.substring(index + 1));
  const brokerIdFromReturnUrl = returnUrlParams.get(paramName);

  return brokerIdFromReturnUrl;
};
