import { fg } from '@lendi-ui/color';
import { my } from '@lendi-ui/spacing';
import { body } from '@lendi-ui/typography';
import * as React from 'react';
import styled from 'styled-components';

const Divider: React.FunctionComponent = () => (
  <DividerWrapper>
    <HorizontalLine />
    <ORText>or</ORText>
    <HorizontalLine />
  </DividerWrapper>
);

export const DividerWrapper = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${my('lg')}
`;

export const HorizontalLine = styled.hr`
  width: 40%;
  height: 0px;
  ${fg('shade.100')}
`;

export const ORText = styled.span`
  ${body({ size: 'md', color: 'shade.400' })}
  font-weight: bold;
`;

export default Divider;
