import * as React from 'react';
import { Wrapper, HeadingWrapper, LinkWrapper } from './index.style';
import { Body } from '@lendi-ui/typography';
import { PageNotFound } from '@lendi-ui/fancy-icon';
import { Button } from '@lendi-ui/button';
import { LALAAuthError } from '../../state/state';
import { useActions } from '../../state/actionProvider';

export interface SSIErrorModalProps {
  error: LALAAuthError | undefined;
  onCloseModal: () => void;
}

export const SSIErrorModal = ({ error, onCloseModal }: SSIErrorModalProps) => {
  const { authorisePopup, resetError } = useActions();

  return (
    <Wrapper>
      <PageNotFound height="7.5em" width="7.5em" />
      <HeadingWrapper color="shade.700" size="md" mt="md" mb="xs">
        Something went wrong
      </HeadingWrapper>
      <Body color="shade.600" align="center" mb="md">
        Sign in with Google did not work, please try again or let us know{' '}
        <LinkWrapper data-testid="contact_us" href="/contactus">
          [here]
        </LinkWrapper>
        .
      </Body>
      <Button
        variant="primary"
        data-testid="try_again"
        onClick={() => {
          if (error) resetError();
          onCloseModal();
          authorisePopup('google-oauth2');
        }}
        isFullWidth={true}
      >
        TRY AGAIN
      </Button>
    </Wrapper>
  );
};
