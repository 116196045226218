import Alert from '@lendi-ui/alert';
import { between, gte } from '@lendi-ui/breakpoint';
import { color } from '@lendi-ui/color';
import { container } from '@lendi-ui/container';
import { depth } from '@lendi-ui/depth';
import Grid, { unit } from '@lendi-ui/grid';
import { mb, ml, mx, my, px } from '@lendi-ui/spacing';
import { Body, body, heading, Heading as LuiHeading } from '@lendi-ui/typography';
import { display } from '@lendi-ui/utils';
import { Button } from '@lendi/ui';
import styled from 'styled-components';

// @ts-ignore
export const ContentUnit = styled.div`
  ${unit({ size: { mobile: 1, tablet: 1 / 2 } })}
  ${px({ mobile: 'sm', tablet: 'md' })}
`;

export const ExpiredUnit = styled.div`
  ${unit({ size: { mobile: 1, tablet: 1 / 2 } })}
  ${px({ mobile: 'sm', tablet: 'md' })}
`;

export const BenefitsUnit = styled(Grid.Unit)`
  display: none;
  ${gte('lg')`
    display: block;
  `}
`;

export const Wrapper = styled.div`
  ${container};
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardWrapper = styled.div`
  ${display({ mobile: 'none', desktop: 'block' })}
  position: absolute;
  width: 250px;
  right: 0;
  top: calc(2.5 * var(--lendi-ui-size));
`;
// Default Sign in
export const DefaultLoginWrapper = styled.div`
  max-width: 978px;
  margin: 0 auto;
  display: flex;
`;

// For AB testing we could have variants, this is the wrapper for that.
export const LoginVariantWrapper = styled.div`
  ${unit({ size: 1 })}
  display: flex;
  justify-content: space-around;
  ${my('md')}
  ${gte('tablet')`
    ${my('lg')}
    justify-content: space-evenly;
  `}
`;

// Left side variant
export const LoginVariantContainer = styled.div`
  ${unit({
    size: { mobile: 0.9, tablet: 3 / 8, desktop: 6 / 16 },
    offset: { mobile: 0, tablet: 0, desktop: 2 / 16 },
  })}
`;

// Right side variant
export const ExtraContainer = styled.div`
  ${unit({
    size: { tablet: 3.5 / 8, desktop: 7 / 16 },
    offset: { tablet: 0, desktop: 1 / 16 },
  })}
  ${display({ mobile: 'none', tablet: 'inherit' })}
`;

export const StyledRequestCode = styled.div`
  ${unit({ size: { mobile: 1 } })}
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  ${unit({ size: { mobile: 1, tablet: 0.9, desktop: 0.6 } })}
`;

export const Heading = styled.div`
  ${unit({ size: { mobile: 1, tablet: 0.9, desktop: 0.7 } })}
  ${heading({ size: 'md', color: 'shade.800' })}
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
`;

export const SubHeading = styled.div`
  ${body({ size: 'md', color: 'shade.700' })}
  line-height: 1.38;
  text-align: center;
  ${my('lg')}
`;

export const Mainpage = styled.div`
  ${container};
  position: relative;
  margin-top: calc(-2 * var(--lendi-ui-size));
  ${gte('desktop')`
    margin-top: calc(-4 * var(--lendi-ui-size));
  `}
`;

export const ContentWrapper = styled.div`
  ${between('mobile', 'tablet')`
    ${mx('xs')}
  `}
`;

export const BeforeWrapper = styled.span`
  white-space: nowrap;
  ${ml('sm')}
  ${body({ size: 'lg', color: 'shade.500' })}
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

export const AlertWrapper = styled(Alert)`
  ${mb('sm')}
`;

export const RFFFieldWrapper = styled.div`
  ${mb('sm')}
`;

export const WordWrapper = styled.span`
  white-space: nowrap;
`;

export const NoBr = styled.span`
  white-space: nowrap;
`;

export const AuthForm = styled.form`
  width: 100%;
  margin: auto;

  ${gte('tablet')`
    width: 320px;
  `}
`;

export const DivMBSM = styled.div`
  ${mb('sm')}
`;

export const Separator = styled.hr`
  ${my('lg')}
  border: none;
  border-bottom: 1px solid ${color('shade.200')};
`;

export const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;
  background: var(--background-page, #f8f8f8);
  color: var(--text-main, #474747);
  font-style: normal;
`;

export const Prompt = styled.div`
  display: flex;
  width: 90%;
  ${gte('tablet')`
    width: 553px;
  `}
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 6px;
  background: var(--background-container, #fff);
  ${depth(1)};
`;

// @ts-ignore
export const PromptHeader = styled(LuiHeading)`
  align-self: stretch;
  font-weight: 400;
  font-size: 24.5px;
  line-height: 28.17px;
  text-align: center;
`;

export const PromptMessage = styled(Body)`
  align-self: stretch;
`;

export const Actions = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  flex-direction: column;
  ${gte('tablet')`
    width: auto;
    flex-direction: row;
  `}
`;

export const YesNoButton = styled(Button)`
  ${gte('tablet')`
    width: 212px;
  `}
  display: flex;
  width: 100%;
  height: 48px;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > span {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
`;
