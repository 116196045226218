import styled from 'styled-components';
import { Heading, Link } from '@lendi-ui/typography';
import { px } from '@lendi-ui/spacing';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20rem;
  ${px('xs')}
`;
// @ts-ignore
export const HeadingWrapper = styled(Heading)`
  font-weight: bold;
`;

export const LinkWrapper = styled(Link)`
  font-weight: normal;
`;
