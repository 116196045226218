import { Button } from '@lendi-ui/button';
import { color, fg, bg } from '@lendi-ui/color';
import styled from 'styled-components';

// @ts-ignore
export const LightButton = styled(Button)`
  && {
    border-color: ${color('shade.200')};
    ${bg('shade.0')}
    ${fg('shade.700')}
  }
`;
