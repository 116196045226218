import { Body, Link } from '@lendi-ui/typography';
import * as React from 'react';
import styled from 'styled-components';
import getColour from '@lendi-ui/commons/colours';
import { useTranslate } from '../../hooks';
import { eventTracking } from '../../helpers/tracking';

const LinkWrapper = styled(Link)`
  white-space: nowrap;
  color: ${(_) => getColour('interaction', 'main')};
`;

interface DisclaimerProps {
  alignment?: 'left' | 'center' | 'right';
  buttonText: string;
}

const Disclaimer = ({ alignment = 'center', buttonText }: DisclaimerProps) => {
  const [privacyPolicyLink, siteName] = useTranslate(['PRIVACY_POLICY_LINK', 'SITE_NAME']);
  const handlePrivacyPolicyClick = () => {
    eventTracking.trackAuthLinkClickEvent(`${siteName}'s Privacy Statement.`);
  };
  const handleEndUserTermsClick = () => {
    eventTracking.trackAuthLinkClickEvent(`${siteName}'s End User Terms.`);
  };
  return (
    <Body size="sm" align={alignment} mt="xxl" data-testid="disclaimer-copy">
      We are collecting your personal information in order to assist you with your home loan application or any other
      ancillary products. By clicking the '{buttonText}' button you have read, consented and agreed to be bound by&nbsp;
      <LinkWrapper
        data-testid="privacy-policy-link"
        href={privacyPolicyLink?.toString()}
        onClick={handlePrivacyPolicyClick}
      >{`${siteName}’s Privacy Statement`}</LinkWrapper>
      {siteName === 'Aussie' && (
        <>
          &nbsp;and the&nbsp;
          <LinkWrapper
            data-testid="end-user-terms-link"
            href={'/about-us/property-end-terms/'}
            onClick={handleEndUserTermsClick}
          >{`End User Terms`}</LinkWrapper>
        </>
      )}
      .
    </Body>
  );
};

export default Disclaimer;
