import Alert from '@lendi-ui/alert';
import { mb } from '@lendi-ui/spacing';
import { Link } from '@lendi-ui/typography';
import * as React from 'react';
import styled from 'styled-components';
import { LALAAuthError, LALAAuthFlow } from '../../state/state';

export interface AlertErrorProps {
  error?: LALAAuthError;
}

export const AlertError: React.FC<AlertErrorProps> = ({ error }) => {
  return error && error.flow === LALAAuthFlow.Passwordless ? (
    <AlertWrapper data-testid="errorMessage" variant="error">
      <>
        {error.authError.description}
        {error && error.authError.contactUs && (
          <>
            {' '}
            Please{' '}
            <Link data-testid="contactUs" href="/contactus" color="error.500">
              contact us
            </Link>{' '}
            to proceed.
          </>
        )}
      </>
    </AlertWrapper>
  ) : (
    <></>
  );
};

export const AlertErrorSsi: React.FC<AlertErrorProps> = ({ error }) => {
  return error && error.flow === LALAAuthFlow.SSI ? (
    <AlertWrapper data-testid="errorMessage" variant="error">
      {error.authError.description}
    </AlertWrapper>
  ) : (
    <></>
  );
};

export const AlertWrapper = styled(Alert)`
  ${mb('sm')}
`;
