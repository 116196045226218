import { Lock } from '@lendi-ui/icon';
import { my } from '@lendi-ui/spacing';
import { Body } from '@lendi-ui/typography';
import * as React from 'react';
import styled from 'styled-components';

const EncriptionText = () => (
  <EncryptionTypeText data-testid="encryption-text">
    <Lock color="shade.400" />
    <Body ml="xxxs" size="xs" color="shade.400">
      256bit encryption
    </Body>
  </EncryptionTypeText>
);

const EncryptionTypeText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${my('sm')}
`;

export default EncriptionText;
