import { Button } from '@lendi/ui/Button';
import { Text } from '@lendi/ui/Typography';
import * as React from 'react';
import { BoldHeading } from '../../components/atoms/BoldHeading';
import { AddressObject, PostcodePicker } from '../../components/PostcodePicker';
import { ButtonWrapper, PageWrapper, SecondSection, SubGrid, ThirdSection } from '../../pages-styles/postcode.style';
import { eventTracking, pageTracking } from '../../helpers/tracking';
const Texts = {
  refinance: {
    header: 'Where are you based?',
    subTitle: 'This helps us give you the best refinancing experience.',
  },
  newPurchase: {
    header: 'Where are you based?',
    subTitle: 'This helps us give you the best buying experience.',
  },
  default: {
    header: 'Where are you based?',
    subTitle: 'This helps us give you a better digital experience.',
  },
};

export const getTexts = () => {
  const l3NewPurchase = sessionStorage.getItem('l3-NEW_HOME_LOAN');
  const l3Refinance = sessionStorage.getItem('l3-REFINANCE');
  if (!!l3NewPurchase && !!l3Refinance) {
    const isRefinanceLatest = JSON.parse(l3Refinance).timestamp > JSON.parse(l3NewPurchase).timestamp;
    return isRefinanceLatest ? Texts.refinance : Texts.newPurchase;
  }
  if (!!l3NewPurchase) {
    return Texts.newPurchase;
  } else if (!!l3Refinance) {
    return Texts.refinance;
  } else {
    return Texts.default;
  }
};
interface PostcodePageProps {
  hidePostcode: (hide: boolean) => void;
}
export interface CustomerPatch {
  postcode: string;
  suburb: string;
  state: string;
}
const PostcodePage = ({ hidePostcode }: PostcodePageProps) => {
  const [canContinue, setCanContinue] = React.useState(true);
  const [patchCustomerObject, setPatchCustomerObject] = React.useState<CustomerPatch | null>(null);
  const text = getTexts();
  React.useEffect(() => {
    pageTracking.trackAuthPage(pageTracking.authTrackingPageName.POST_CODE);
  }, []);
  const onNext = async () => {
    if (
      !patchCustomerObject ||
      (patchCustomerObject && (!patchCustomerObject.postcode || !patchCustomerObject.suburb))
    ) {
      setCanContinue(false);
      return;
    }
    eventTracking.trackAuthButtonClickEvent('SSO', 'NEXT', 'primary');
    hidePostcode(true);
  };
  const onSelect = (selectedValue: AddressObject) => {
    eventTracking.trackAuthButtonClickEvent(JSON.stringify(selectedValue), 'Postcode or Suburb');
    setPatchCustomerObject({
      state: selectedValue.state!,
      postcode: selectedValue.postcode!,
      suburb: selectedValue.suburb!,
    });
  };
  return (
    <PageWrapper>
      <BoldHeading
        size="lg"
        as="h1"
        color="shade.700"
        mt="xl"
        mb="sm"
        align="center"
        data-testid="login-page-heading"
      ></BoldHeading>
      <SubGrid>
        <Text tag="h3" variant="title2" align="center" data-testid="postcode-page-heading">
          {text.header}
        </Text>
        <SecondSection>
          <Text tag="p" variant="body1" align="center" data-testid="postcode-page-subheading">
            {text.subTitle}
          </Text>
        </SecondSection>
        <ThirdSection>
          <PostcodePicker
            onSelect={onSelect}
            onChange={() => setCanContinue(true)}
            onReset={() => {}}
            canContinue={canContinue}
          />
        </ThirdSection>
        <ButtonWrapper>
          <Button
            data-testid={'submit-postcode'}
            size="md"
            onPress={onNext}
            variant="primary"
            type="submit"
            width="200px"
          >
            Next
          </Button>
        </ButtonWrapper>
      </SubGrid>
    </PageWrapper>
  );
};

export default PostcodePage;
