import { BtnGoogleLightNormalIos } from '@lendi-ui/fancy-icon';
import Modal from '@lendi-ui/modal';
import { MarginOptions, pb, px } from '@lendi-ui/spacing';
import Spinner from '@lendi-ui/spinner';
import { useSession } from '@lendi/lala-react';
import * as Cookies from 'js-cookie';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';
import { eventTracking } from '../../helpers/tracking';
import { useActions } from '../../state/actionProvider';
import { LALAAuthError, LALAAuthFlow } from '../../state/state';
import { LightButton } from '../LightButton';
import { SSIErrorModal } from '../SSIErrorModal';

const ModalWrapper = styled(Modal)`
  z-index: 6;
  ${px('lg')}
  ${pb('sm')}
`;

export interface GoogleSignInButtonProps {
  error: LALAAuthError | undefined;
  verifying: boolean;
}

export interface GoogleButtonProps extends GoogleSignInButtonProps, MarginOptions {
  onClick: ((event: React.SyntheticEvent<Element, Event>) => void) | undefined;
}

export const GoogleSignInButton: React.FC<GoogleSignInButtonProps> = (props) => {
  const { authorisePopup, resetError } = useActions();
  const { brand } = useSession();
  const ldClient = useLDClient();
  let targetId = Cookies.get('targetId') || v4();
  Cookies.set('hasSSOAccessed', 'true');
  const handleClick = async () => {
    eventTracking.trackAuthButtonClickEvent('SSO', 'Sign in with Google', 'secondary');
    await ldClient?.identify({
      kind: 'multi',
      unauthUser: {
        key: targetId,
        isTeamView: false,
        hasSSOAccessed: true,
        brand,
      },
    });

    if (props.error) resetError();
    authorisePopup('google-oauth2');
  };

  return <GoogleButton onClick={handleClick} {...props} />;
};

const GoogleButton: React.FC<GoogleButtonProps> = ({ error, onClick, verifying, children: _children, ...margin }) => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (error && error.flow === LALAAuthFlow.SSI) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [error]);

  return (
    <>
      <LightButton
        data-testid="requestGoogleSignIn"
        variant="secondary"
        isFullWidth
        isDisabled={verifying}
        onClick={onClick}
        {...margin}
        before={<BtnGoogleLightNormalIos width="3.5em" height="3.5em" />}
      >
        {verifying ? <Spinner variant="dark" data-testid="spinner" /> : 'Sign in with Google'}
      </LightButton>

      <ModalWrapper
        isVisible={openModal}
        size="md"
        onHide={() => setOpenModal(!openModal)}
        data-testid="ssi_error_modal"
      >
        <Modal.Content>
          <SSIErrorModal error={error} onCloseModal={() => setOpenModal(false)} />
        </Modal.Content>
      </ModalWrapper>
    </>
  );
};

export default GoogleButton;
