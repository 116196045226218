import { pt } from '@lendi-ui/spacing';
import Info from '@lendi-ui/icon/Info';
import styled from 'styled-components';

export const Alert = styled.div`
  ${pt('sm')}
`;

export const InfoWrapper = styled(Info)`
  display: inline;
`;
